<template>
	<div class="center">
		<div class="child">
			<fa class="error-icon" icon="exclamation-circle" />
			<div class="spacer-20"></div>
			<h2>Subscription Error</h2>
			<br />
			<p>
				Updating your subscription failed or was canceled. Please try
				again or
				<a href="mailto:support@riskmemo.com">email support</a>.
			</p>
			<div class="spacer-40"></div>
			<router-link class="btn btn-primary" to="/account/subscription"
				>Back to account</router-link
			>
		</div>
	</div>
</template>

<script>
import { useRouter } from "vue-router";
import { onMounted } from "vue";
import { event } from "vue-gtag";

export default {
	name: "SubscriptionError",
	setup() {
		const router = useRouter();

		onMounted(() => {
			event("subscription_error");
		});
	},
};
</script>

<style lang="scss" scoped>
.center {
	position: relative;
	height: 100vh;
	text-align: center;
}

.child {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin: 0;
}
.error-icon {
	font-size: 3em;
	color: $rm-grey;
}
</style>
