import { ref, watchEffect } from 'vue'

const getDocument = (collectionRef) => {
  const document = ref(null)
  const error = ref(null)

  const unsub = collectionRef.onSnapshot(doc => {
    if (doc.data()) {
      document.value = { ...doc.data(), id: doc.id }
      error.value = null
    } else {
      error.value = 'No data. Check internet connection.'
    }

  }, err => {
    console.log("Error message: ", err.message)
    console.log('Error code: ', err.code)
    error.value = 'Could not fetch document'
  })

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub());
  });

  return { error, document }
}

export default getDocument