<template>
	<div class="vertical-center">
		<div>
			<h2>{{ message }}</h2>

			<div class="spacer-20"></div>
			<page-loading v-if="isLoading" />
		</div>
	</div>
</template>

<script>
import { ref, watchEffect, onMounted } from "vue";
import PageLoading from "../../../components/PageLoading.vue";
import { projectFirestore } from "../../../firebase/config";
import getCollection from "../../../composables/getCollection";
import { useRouter } from "vue-router";
import { useMeta } from "vue-meta";
import { event } from "vue-gtag";
import { useSessionStore } from "../../../stores/sessionStore";
import { storeToRefs } from "pinia";

export default {
	name: "Success",
	components: { PageLoading },
	setup() {
		useMeta({ title: "Success" });
		// const { user } = getUser();
		const isLoading = ref(true);
		const message = ref("Processing your subscription");
		const router = useRouter();
		const sessionStore = useSessionStore();
		const { user, companyDoc } = storeToRefs(sessionStore);

		let urlParams = new URLSearchParams(window.location.search);

		const db = projectFirestore;

		// stream the users subscription collection so we can listen for new entries
		// const subscriptionsRef = db
		// 	.collection("users")
		// 	.doc(user.value.uid)
		// 	.collection("subscriptions")
		// 	.where("status", "in", ["active", "trialing"]);
		// const { documents } = getCollection(subscriptionsRef);

		watchEffect(() => {
			// there should only ever be one active or trialing subscription
			if (companyDoc.value) {
				// The company doc should also have a trialing or active subscription status
				if (companyDoc.value.subscription.status == "active") {
					isLoading.value = false;
					clearTimeout(errorTimeout);
					router.push({ name: "Subscription" });
				}
			}
		});

		// If we can't confirm the subscription in 10 seconds, show an error message
		const errorTimeout = setTimeout(() => {
			isLoading.value = false;
			message.value =
				"Sorry, something went wrong. Please refresh the page or contact us.";
		}, 10000);

		onMounted(() => {
			event("subscription_success");
		});

		return { message, isLoading, message };
	},
};
</script>

<style lang="scss"></style>
