<template>
    <div class="center">
        <!-- Loading -->
        <page-loading class="child" v-if="isLoadingPage && !equipments" />
        <div v-else>
            <p class="child">{{ renewText }}</p>
            <button
                v-if="isOwner"
                class="child marginTop40 btn btn-primary"
                v-on:click.prevent="submit"
                :disabled="isFetching"
            >{{ isFetching ? "Loading..." : buttonText }}</button>
        </div>
    </div>
</template>

<script>
import { computed, ref, onBeforeMount, watchEffect } from "@vue/runtime-core";
import account from "../../../composables/account";
import { projectFirestore } from "../../../firebase/config";
import getDocumentOnce from "../../../composables/getDocumentOnce";
import { getCheckoutURL } from "../../../composables/createSubscription";
import EnvUtils from "../../../misc/env";
import PageLoading from "../../../components/PageLoading.vue";
import getCollection from "../../../composables/getCollection";

export default {
    name: "RenewSubscription",

    components: { PageLoading },

    setup() {
        const { user, companyId } = account();

        const ownerUid = ref();
        const isFetching = ref(false);
        const isLoadingPage = ref(false);
        let buttonText = ref();

        let usedContributors;
        let usedEquipments;

        const companyDoc = projectFirestore
            .collection("companies")
            .doc(companyId);
        const equipmentCollection = companyDoc.collection("equipment");

        const contributorsCollection = companyDoc
            .collection("employees")
            .where("role", "!=", "Employee");

        const { documents: equipment } = getCollection(equipmentCollection);

        onBeforeMount(async () => {
            isLoadingPage.value = true;

            const { document } = await getDocumentOnce(companyDoc);

            let contributorsData = await contributorsCollection.get();
            usedContributors =
                document.value.subscription.used_contributors ??
                contributorsData.docs.length;

            buttonText.value = document.value.subscription.trial_period
                ? "Start Trial"
                : "Purchase";

            ownerUid.value = document.value.ownerUid;
            isLoadingPage.value = false;
        });

        const isOwner = computed(() => {
            return user.value && ownerUid.value === user.value.uid;
        });

        const renewText = computed(() => {
            let text;
            if (isOwner.value) {
                text = "Please renew your subscription.";
            } else {
                text =
                    "Please contact " +
                    user.value.displayName +
                    " to renew subscription.";
            }
            return text;
        });

        watchEffect(() => {
            if (equipment.value) {
                usedEquipments = -1 * ~(equipment.value.length / 500);
            }
        });

        const submit = async () => {
            if (isFetching.value) return;

            isFetching.value = true;

            const successURL = EnvUtils.PORTAL + "/success";
            const failureURL = EnvUtils.PORTAL + "/checklists/templates";

            await getCheckoutURL(
                successURL,
                failureURL,
                usedContributors,
                usedEquipments,
                Rewardful.referral,
                null
            );

            setTimeout(() => {
                isFetching.value = false;
            }, 5000);
        };

        return {
            isOwner,
            submit,
            isFetching,
            isLoadingPage,
            renewText,
            equipment,
            buttonText,
        };
    },
};
</script>

<style lang="scss" scoped>
.center {
    position: relative;
    height: 100vh;
}

.child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
}

.marginTop40 {
    margin-top: 40px;
}
</style>
