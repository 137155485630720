import { projectFirestore } from "../firebase/config";
import fetchProducts from "./getAllProducts";
import getUser from "./getUser";

// ** Creates Checkout URL that user can use for creating subscription */
const createCheckoutCollection = async (subData) => {
	const { user } = getUser();

	const checkoutCollection = projectFirestore
		.collection("users")
		.doc(user.value.uid)
		.collection("checkout_sessions");

	const documentRef = await checkoutCollection.add(subData);

	return documentRef;
};

const getCheckoutURL = async (
	success_url,
	cancel_url,
	contributors,
	equipments,
	interval,
	referralId,
	currency
) => {
	const products = await fetchProducts();

	if (products.length === 0) {
		console.error("No products found");
		return null;
	}

	var line_items = [];

	// For 14 days trial, taking one contributor and 500 equipments (aka 1 unit)
	products.forEach((product) => {
		let quantity;
		if (product.name === "Contributor") {
			quantity = contributors;
		} else {
			quantity = equipments;
		}
		product.prices.forEach((price) => {
			if (
				price.interval === interval &&
				price.currency === currency.toLowerCase()
			) {
				line_items.push({
					price: price.id,
					quantity: quantity,
				});
			}
		});
	});

	if (line_items.length === 0) {
		console.error("No line items found");
		return null;
	}

	var subData = {
		line_items: line_items,
		success_url: success_url,
		cancel_url: cancel_url,
		client_reference_id: referralId,
	};

	const checkoutCollection = await createCheckoutCollection(subData);

	// Stripe will update the document with the url, hence listening for the update
	checkoutCollection.onSnapshot((snap) => {
		const { error, url } = snap.data();
		if (error) {
			console.log("Error while creating subscription", error);
			return null;
		}

		if (url) {
			window.location.assign(url);
		}
	});
};

export { getCheckoutURL };
