<template>
	<div class="page-container">
		<!-- Loading -->
		<div class="table-row-shimmer shine" v-if="!company"></div>

		<div class="row tile" v-if="company && role && role == 'Admin'">
			<div class="split flex-c">
				<!-- Col 1 -->
				<UpdateCompanyForm v-if="company" :companyId="company.id" />

				<!-- Col 2 -->
				<div class="row info-list table-row-container">
					<div class="collection-title">
						<h3>Employees:</h3>
					</div>
					<div class="table-row">
						<div class="row-left">
							<div class="title">Admins</div>
						</div>
						<div class="row-right">
							<div class="actions">
								<div class="circle-number" v-if="admins">
									{{ admins.length }}
								</div>
							</div>
						</div>
					</div>
					<div class="table-row">
						<div class="row-left">
							<div class="title">Contributors</div>
						</div>
						<div class="row-right">
							<div class="actions">
								<div class="circle-number" v-if="contributors">
									{{ contributors.length }}
								</div>
							</div>
						</div>
					</div>
					<div class="table-row">
						<div class="row-left">
							<div class="title">Team Members</div>
						</div>
						<div class="row-right">
							<div class="actions">
								<div class="circle-number" v-if="employees">
									{{ employees.length }}
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Col 3 -->
				<div class="row info-list table-row-container">
					<div class="collection-title">
						<h3>Equipment:</h3>
					</div>

					<div class="table-row">
						<div class="row-left">
							<div class="title">Active Equipment</div>
						</div>
						<div class="row-right">
							<div class="actions">
								<div class="circle-number" v-if="active">
									{{ active.length }}
								</div>
							</div>
						</div>
					</div>
					<div class="table-row">
						<div class="row-left">
							<div class="title">Archived Equipment</div>
						</div>
						<div class="row-right">
							<div class="actions">
								<div class="circle-number" v-if="archived">
									{{ archived.length }}
								</div>
							</div>
						</div>
					</div>

					<div class="spacer-20"></div>

					<div class="table-row">
						<div class="row-left">
							<div class="title">Equipment names</div>
						</div>
						<div class="row-right">
							<div class="actions">
								<router-link
									:to="{
										name: 'ManageTags',
										query: { tagType: 'equipmentNames' },
									}"
								>
									<div class="actions-icon-btn">
										<fa icon="arrow-right" />
									</div>
								</router-link>
							</div>
						</div>
					</div>

					<div class="help-text">
						Add or delete Equipment Names your staff can choose from
						in form dropdowns
					</div>

					<div v-if="isrmAdmin">
						<div class="spacer-20"></div>
						<label for="">Sync Company Doc</label>
						<div class="table-row">
							<div class="row-left">
								<input v-model="compIdToUpdate" type="text" />
							</div>
							<div class="row-right">
								<div class="actions">
									<div
										class="actions-icon-btn"
										@click="updateComp"
									>
										<fa icon="arrow-right" />
									</div>
								</div>
							</div>
						</div>
						<div class="spacer-20"></div>
						<label for="">Sync Equipment Names</label>
						<div class="table-row">
							<div class="row-left">
								<input v-model="compIdToUpdate" type="text" />
							</div>
							<div class="row-right">
								<div class="actions">
									<div
										class="actions-icon-btn"
										@click="updateEquipmentNames"
									>
										<fa icon="arrow-right" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<router-view v-slot="{ Component }">
		<transition name="fade" mode="in-out">
			<component :is="Component" />
		</transition>
	</router-view>
</template>

<script>
import { computed, ref } from "@vue/runtime-core";
import account from "../../composables/account";
import getDocument from "../../composables/getDocument";
import { projectFirestore, projectFunctions } from "../../firebase/config";

import PageLoading from "../PageLoading.vue";
import UpdateCompanyForm from "../../components/account/UpdateCompanyForm.vue";
import getCollection from "../../composables/getCollection";
import equipmentCollection from "../../composables/equipmentsCollection";

export default {
	name: "AccountSettings",
	components: { PageLoading, UpdateCompanyForm },
	setup() {
		const { companyId, role, user } = account();

		const isrmAdmin = computed(() => {
			if (user.value.email == "admin@riskmemo.com") return true;
		});

		const { syncEquipmentNames } = equipmentCollection();

		//   Company Data
		const db = projectFirestore;
		const companyRef = db.collection("companies").doc(companyId);

		const { document: company } = getDocument(companyRef);

		//   Equipment data
		const equipmentRef = companyRef.collection("equipment");
		const { documents: equipment } = getCollection(equipmentRef);

		const active = computed(() => {
			let activeEquipment = null;

			if (equipment.value) {
				activeEquipment = equipment.value.filter((item) => {
					return item.archived == false;
				});
			}
			return activeEquipment;
		});

		const archived = computed(() => {
			let archivedEquipment = null;

			if (equipment.value) {
				archivedEquipment = equipment.value.filter((item) => {
					return item.archived == true;
				});
			}
			return archivedEquipment;
		});

		//   Employee Data
		const employeesRef = companyRef.collection("employees");
		const { documents: employeesData } = getCollection(employeesRef);

		const employees = computed(() => {
			let filteredEmployees = null;

			if (employeesData.value) {
				filteredEmployees = employeesData.value.filter((e) => {
					return e.role == "Employee";
				});
			}

			return filteredEmployees;
		});

		const contributors = computed(() => {
			let filteredContributors = null;

			if (employeesData.value) {
				filteredContributors = employeesData.value.filter((e) => {
					return e.role == "Contributor";
				});
			}

			return filteredContributors;
		});

		const admins = computed(() => {
			let filteredAdmins = null;

			if (employeesData.value) {
				filteredAdmins = employeesData.value.filter((e) => {
					return e.role == "Admin";
				});
			}

			return filteredAdmins;
		});

		const compIdToUpdate = ref(null);

		const updateComp = async () => {
			const triggerUpdate = projectFunctions.httpsCallable(
				"triggerUpdateSubscriptionInCompany"
			);

			try {
				console.log("Company to update: ", compIdToUpdate.value);
				const { res } = await triggerUpdate({
					companyId: compIdToUpdate.value,
				});
				console.log(res);
			} catch (err) {
				console.log(err);
			}
		};

		const updateEquipmentNames = async () => {
			try {
				await syncEquipmentNames(compIdToUpdate.value);
				console.log("Equipment names synced");
			} catch (err) {
				console.error(err);
			}
		};

		return {
			compIdToUpdate,
			updateComp,
			company,
			equipment,
			active,
			archived,
			admins,
			contributors,
			employees,
			role,
			isrmAdmin,
			updateEquipmentNames,
		};
	},
};
</script>

<style lang="scss" scoped>
.table-row-shimmer {
	height: 300px;
	border-radius: 1em;
}
.info-list {
	//   width: 33%;
	width: 100%;
	padding: 0 1em;
	@include respond(small) {
		width: 33%;
	}

	@include respond(large) {
		// padding: 0 1em;
	}
}
.table-row {
	padding: 0 5px;

	@include respond(large) {
		padding: 10px 20px;
	}
	.row-left {
		.title {
			margin-left: 0;
			padding: 0;
			font-size: 12px !important;

			@include respond(large) {
				font-size: 15px !important;
				padding: 0 1em !important;
			}
		}
	}

	&:hover {
		box-shadow: none;
		padding: 10px 20px;
	}
}

.help-text {
	margin: 0 1em 1em;
	font-size: 0.8em;
	color: $rm-grey;
}

.acc-stats {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.circle-number {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 35px;
	height: 35px;
	border-radius: 35px;
	border: 2px solid $rm-blue;
	background-color: $rm-light-grey;
	color: $rm-blue;
	font-size: 1em;
	font-weight: bold;
	padding: 5px;
}
.row {
	margin-top: 5em;

	@include respond(medium) {
		margin-top: 0;
	}
}
</style>
