import { projectFirestore } from "../firebase/config";
// import getUser from "./getUser";
import _ from "lodash";

const allStatuses = [
	"active",
	"trialing",
	"unpaid",
	"canceled",
	"incomplete",
	"incomplete_expired",
	"past_due",
];

const failedStatuses = ["canceled", "incomplete", "incomplete_expired"];

const fetchSubscription = async (ownerId, subId) => {
	// const { user } = getUser();
	try {
		if (subId) {
			console.log(ownerId, subId);
			const sub = await projectFirestore
				.collection("users")
				.doc(ownerId)
				.collection("subscriptions")
				.doc(subId)
				.get();

			return sub.exists ? { id: sub.id, ...sub.data() } : null;
		} else {
			const subscriptions = await projectFirestore
				.collection("users")
				.doc(ownerId)
				.collection("subscriptions")
				.where("status", "in", [
					"active",
					"trialing",
					"past_due",
					"unpaid",
				])
				.get();

			return !_.isEmpty(subscriptions.docs)
				? {
						id: subscriptions.docs[0].id,
						...subscriptions.docs[0].data(),
				  }
				: null;
		}
	} catch (e) {
		console.log("Fetch sub error: ", e);
	}
};

export default fetchSubscription;
