import { projectFirestore } from "../firebase/config";
import useCookies from "./useCookies";

let latestVersion = 4;

// Assign the customers pricing version
function assignVersion(createdAt) {
	var date = new Date(createdAt);
	if (date.getTime() < new Date(2023, 3, 1).getTime()) {
		return 1;
	} else if (date.getTime() < new Date(2024, 2, 1).getTime()) {
		return 2;
	} else if (date.getTime() < new Date(2024, 5, 1).getTime()) {
		return 3;
	} else {
		return latestVersion;
	}
}

// Fetch all products with prices
const fetchProducts = async () => {
	try {
		// Current users company Id
		const { getCookie } = useCookies();
		const companyId = getCookie("rm_cId");

		let company = await projectFirestore
			.collection("companies")
			.doc(companyId)
			.get();

		if (!company.exists) {
			console.error("Could not fetch company");
			return null;
		}

		let version = assignVersion(company.data().createdAt.toDate());
		let currentStatus = company.data().subscription.status;

		if (currentStatus === "canceled" || currentStatus === "free") {
			version = latestVersion;
		}

		var result = [];
		const products = await projectFirestore
			.collection("products")
			.where("active", "==", true)
			.where("metadata.version", "==", version.toString())
			.get();

		result = await Promise.all(
			products.docs.map(async (product) => {
				const prices = await projectFirestore
					.collection("products")
					.doc(product.id)
					.collection("prices")
					.get();

				const productData = {
					id: product.id,
					...product.data(),
					prices: prices.docs.map((price) => {
						return {
							id: price.id,
							...price.data(),
						};
					}),
				};

				return productData;
			})
		);

		return result;
	} catch (error) {
		console.error("Error fetching products: ", error);
	}
};

export default fetchProducts;
