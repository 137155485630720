<template>
	<div class="modal">
		<div class="modal-container">
			<div class="modal-content tile">
				<form action class="rm-form">
					<div class="modal-header">
						<div class="left">
							<div class="modal-title">
								<h3 v-if="tagType == 'equipmentTags'">
									Equipment tags:
								</h3>
								<h3 v-if="tagType == 'equipmentNames'">
									Equipment names:
								</h3>
								<h3 v-if="tagType == 'searchTags'">
									Checklist search tags:
								</h3>
							</div>
							<div class="help-text">
								<p>
									Note: Adding or removing items here does not
									effect existing checklists or equipment.
								</p>

								<p
									class="warning"
									v-if="tagType == 'searchTags'"
								>
									Warning: A search tag is here because it
									exists on a checklist. Only delete tags that
									have been added in error.
								</p>
							</div>
						</div>
						<div class="right">
							<router-link
								class="close"
								:to="{ name: 'Account' }"
							>
								<fa icon="times" />
							</router-link>
						</div>
					</div>

					<div class="modal-body">
						<div class="row add-form">
							<form action class="rm-form" @submit.prevent>
								<div class="split">
									<div class="form-input">
										<input
											type="text"
											placeholder="Add new..."
											v-model="itemName"
										/>
									</div>
									<div class="btn-add--container">
										<button
											class="btn btn-success"
											@click="add(itemName)"
										>
											+
										</button>
									</div>
								</div>
							</form>
						</div>
						<div class="spacer-20" v-if="!loading"></div>
						<page-loading v-if="loading" />
						<div v-else>
							<div
								class="scroll-container table-row-container"
								v-if="tagType == 'equipmentTags' && !loading"
							>
								<div
									v-for="tag in equipmentTags"
									:key="tag"
									v-if="equipmentTags"
								>
									<div class="table-row">
										<div class="row-left">
											<div class="title">{{ tag }}</div>
										</div>
										<div class="row-right">
											<div class="actions">
												<div
													class="actions-icon-btn"
													@click="remove(tag)"
												>
													<fa icon="trash" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							class="scroll-container table-row-container"
							v-if="tagType == 'equipmentNames' && !loading"
						>
							<div
								v-for="name in equipmentNames"
								:key="name"
								v-if="equipmentNames"
							>
								<div class="table-row">
									<div class="row-left">
										<div class="title">{{ name }}</div>
									</div>
									<div class="row-right">
										<div class="actions">
											<div
												class="actions-icon-btn"
												@click="remove(name)"
											>
												<fa icon="trash" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							class="scroll-container table-row-container"
							v-if="tagType == 'searchTags' && !loading"
						>
							<div
								v-for="tag in templateTags"
								:key="tag"
								v-if="templateTags"
							>
								<div class="table-row">
									<div class="row-left">
										<div class="title">{{ tag }}</div>
									</div>
									<div class="row-right">
										<div class="actions">
											<div
												class="actions-icon-btn"
												@click="remove(tag)"
											>
												<fa icon="trash" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from "@vue/reactivity";
import equipmentCollection from "../../composables/equipmentsCollection";
import templateCollection from "../../composables/templateCollection";
import { onBeforeMount, onMounted } from "@vue/runtime-core";
import PageLoading from "../../components/PageLoading.vue";
import { useRoute } from "vue-router";

export default {
	components: { PageLoading },
	name: "ManageTags",
	setup() {
		const route = useRoute();
		const tagType = route.query.tagType;

		const itemName = ref("");

		const loading = ref(false);

		const {
			updateEquipmentTag,
			updateEquipmentNames,
			getEquipmentTags,
			getEquipmentNames,
			deleteEquipmentNames,
			deleteEquipmentTag,
		} = equipmentCollection();
		const { updateTemplateTags, getTemplateTags, deleteTemplateTag } =
			templateCollection();

		const templateTags = ref([]);
		const equipmentTags = ref([]);
		const equipmentNames = ref([]);

		onMounted(async () => {
			loading.value = true;
			var data = await getTemplateTags();

			templateTags.value = data.tags;
			equipmentTags.value = await getEquipmentTags();
			equipmentNames.value = await getEquipmentNames();
			loading.value = false;
		});

		onBeforeMount(async () => {});

		const remove = (id) => {
			if (tagType == "equipmentTags") {
				deleteEquipmentTag(id);
				equipmentTags.value.splice(equipmentTags.value.indexOf(id), 1);
			}

			if (tagType == "equipmentNames") {
				deleteEquipmentNames(id);
				equipmentNames.value.splice(
					equipmentNames.value.indexOf(id),
					1
				);
			}

			if (tagType == "searchTags") {
				deleteTemplateTag(id);
				templateTags.value.splice(templateTags.value.indexOf(id), 1);
			}
		};

		const add = (id) => {
			if (tagType == "equipmentTags") {
				updateEquipmentTag(id);
				equipmentTags.value.splice(0, 0, id);
			}

			if (tagType == "equipmentNames") {
				updateEquipmentNames(id);
				equipmentNames.value.splice(0, 0, id);
			}

			if (tagType == "searchTags") {
				updateTemplateTags(id);
				templateTags.value.splice(0, 0, id);
			}

			itemName.value = "";
		};

		return {
			tagType,
			itemName,
			remove,
			add,
			templateTags,
			equipmentTags,
			equipmentNames,
			loading,
		};
	},
};
</script>

<style lang="scss" scoped>
.scroll-container {
	max-height: 230px;
	overflow: auto;
	background-color: $rm-light-grey;
	padding: 0.5em;
	border-radius: 5px;
}
.modal-content {
	margin: 0 auto;
	width: 40%;
}
.help-text {
	width: 80%;
	margin-top: 0.5em;
	p {
		font-size: 0.8em;
		color: $rm-grey;
	}
	.warning {
		margin-top: 5px;
		color: $rm-danger;
		font-weight: bold;
	}
}
.add-form {
	padding: 0.5em 1em 0 40px;
}
.btn-add--container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 90px;
	padding-bottom: 1em;
}
.btn-success {
	padding: 0;
	height: 22px;
	width: 22px;
	border-radius: 50%;
	min-width: 22px;
	font-size: 16px;
}
</style>
