<template>
	<div class="modal">
		<div class="modal-container">
			<div class="modal-content tile">
				<form class="rm-form" @submit.prevent="updateSub">
					<div class="modal-header">
						<div class="left">
							<div class="modal-title">
								<h3>Update Subscription</h3>
							</div>
						</div>
						<div class="right">
							<router-link
								:to="{
									name: 'Subscription',
								}"
							>
								<fa class="icon" icon="times" />
							</router-link>
						</div>
					</div>
					<div class="modal-body">
						<div class="form-input flex">
							<label class="switch">
								<input
									type="checkbox"
									v-model="selectedInterval"
								/>
								<span class="slider round"></span>
							</label>
							<div class="interval-switch-text">
								<p v-if="interval === 'month'">
									Billed monthly. Switch to annual for a 25%
									discount!
								</p>
								<p v-else>
									Billed annually (25% discount applied)
								</p>
							</div>
						</div>
						<div class="spacer-20"></div>
						<div class="split">
							<div class="item">
								<h3>Contributors:</h3>
								<p class="help-text">
									{{ currencySymbol
									}}{{
										interval === "year"
											? annualContributorPrice
											: monthlyContributorPrice
									}}
									per Contributor per {{ interval }}
								</p>
							</div>
							<div class="qty">
								<div class="form-input">
									<input
										type="number"
										v-model="contributors"
										:min="minContributors"
									/>
								</div>
							</div>
						</div>

						<div class="split">
							<div class="item">
								<h3>Equipment Packs:</h3>
								<p class="help-text">
									{{ currencySymbol
									}}{{
										interval === "year"
											? annualEquipmentPrice
											: monthlyEquipmentPrice
									}}
									per Equipment Pack per {{ interval }}
								</p>
							</div>

							<div class="qty">
								<div class="form-input">
									<input
										type="number"
										v-model="equipments"
										:min="minEquipments"
									/>
								</div>
							</div>
						</div>
						<div class="form-messages" v-if="!error">
							<p class="help-text p-1rem">
								Upgrading will charge a prorated amount for the
								remaining days of the current billing cycle.
							</p>
						</div>
						<div class="form-messages" v-if="error">
							<p>{{ error }}</p>
						</div>
						<div class="form-submit">
							<button
								class="btn btn-success"
								:disabled="
									(contributors == minContributors &&
										equipments == minEquipments &&
										interval ==
											companyDoc.subscription.interval) ||
									isLoading
								"
							>
								{{
									isLoading
										? "Updating..."
										: "Update Subscription"
								}}
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import upgradeSubscription from "../../../composables/upgradeSubscription";
import { computed, onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useSessionStore } from "../../../stores/sessionStore";
import { storeToRefs } from "pinia";
import { projectFirestore, projectFunctions } from "@/firebase/config";

export default {
	name: "Upgrade",
	setup() {
		const router = useRouter();

		const sessionStore = useSessionStore();
		const { companyDoc } = storeToRefs(sessionStore);

		const isLoading = ref(false);
		const isFetchingProduct = ref(false);
		const error = ref(null);

		// Fixed values
		const currency = ref(null);
		const minContributors = ref(null);
		const minEquipments = ref(null);

		// The selected number of contributors and equipments
		const contributors = ref(1);
		const equipments = ref(1);

		const selectedInterval = ref(true);
		const interval = computed(() => {
			return selectedInterval.value ? "year" : "month";
		});

		// Price refs
		const annualContributorPrice = ref(null);
		const monthlyContributorPrice = ref(null);
		const annualEquipmentPrice = ref(null);
		const monthlyEquipmentPrice = ref(null);

		const currencySymbol = ref("£");

		// Price ids
		const yearlyPriceIdForContributor = ref(null);
		const monthlyPriceIdForContributor = ref(null);
		const yearlyPriceIdForEquipment = ref(null);
		const monthlyPriceIdForEquipment = ref(null);

		onMounted(async () => {
			if (!companyDoc.value) {
				// Will redirect the user back if the user refreshes the page
				router.push({ name: "Subscription" });
			}
			contributors.value = companyDoc.value.subscription.max_contributors;
			minContributors.value =
				companyDoc.value.subscription.max_contributors;

			console;
			equipments.value = companyDoc.value.subscription.max_equipments;
			minEquipments.value = companyDoc.value.subscription.max_equipments;

			currency.value = companyDoc.value.subscription.currency;

			if (companyDoc.value.subscription.interval === "year") {
				selectedInterval.value = true;
			} else {
				selectedInterval.value = false;
			}

			// to fetch price id for monthly and yearly subscription
			isFetchingProduct.value = true;

			if (currency.value === "usd") {
				currencySymbol.value = "$";
			} else if (currency.value === "eur") {
				currencySymbol.value = "€";
			} else {
				currencySymbol.value = "£";
			}

			const equipmentPricesRef =
				companyDoc.value.subscription.equipment_productRef
					.collection("prices")
					.where("currency", "==", currency.value);

			const equipmentPrices = await equipmentPricesRef.get();
			const contributorPricesRef =
				companyDoc.value.subscription.contributor_productRef
					.collection("prices")
					.where("currency", "==", currency.value);
			const contributorPrices = await contributorPricesRef.get();

			equipmentPrices.forEach((doc) => {
				if (doc.data().interval === "year") {
					yearlyPriceIdForEquipment.value = doc.id;
					annualEquipmentPrice.value = doc.data().unit_amount / 100;

					if (annualEquipmentPrice.value === 0) {
						annualEquipmentPrice.value = 88.56;
					}
				} else {
					monthlyPriceIdForEquipment.value = doc.id;
					monthlyEquipmentPrice.value = doc.data().unit_amount / 100;

					if (monthlyEquipmentPrice.value === 0) {
						monthlyEquipmentPrice.value = 9;
					}
				}
			});

			contributorPrices.forEach((doc) => {
				if (doc.data().interval === "year") {
					yearlyPriceIdForContributor.value = doc.id;
					annualContributorPrice.value = doc.data().unit_amount / 100;
				} else {
					monthlyPriceIdForContributor.value = doc.id;
					monthlyContributorPrice.value =
						doc.data().unit_amount / 100;
				}
			});

			isFetchingProduct.value = false;
		});

		const updateSub = async () => {
			isLoading.value = true;
			error.value = null;

			let items = [];

			if (interval.value === "year") {
				console.log("year");
				items.push({
					id: companyDoc.value.subscription.contributor_id,
					price: yearlyPriceIdForContributor.value,
					quantity: contributors.value,
				});
				items.push({
					id: companyDoc.value.subscription.equipment_id,
					price: yearlyPriceIdForEquipment.value,
					quantity: equipments.value,
				});
			} else {
				items.push({
					id: companyDoc.value.subscription.contributor_id,
					price: monthlyPriceIdForContributor.value,
					quantity: contributors.value,
				});
				items.push({
					id: companyDoc.value.subscription.equipment_id,
					price: monthlyPriceIdForEquipment.value,
					quantity: equipments.value,
				});
			}

			const update = projectFunctions.httpsCallable("updateSubscription");

			try {
				const result = await update({
					subId: companyDoc.value.subscription.id,
					items: items,
				});

				if (result.data.error) {
					error.value = result.data.error;
					isLoading.value = false;
					return;
				}

				isLoading.value = false;
				router.push({ name: "Success" });
			} catch (e) {
				console.error("Unable to upgrade subscription", e);
				error.value = "Unable to upgrade subscription";
				isLoading.value = false;
			}
		};

		return {
			isLoading,
			error,
			updateSub,
			interval,
			selectedInterval,
			contributors,
			equipments,
			minContributors,
			minEquipments,
			annualContributorPrice,
			annualEquipmentPrice,
			monthlyContributorPrice,
			monthlyEquipmentPrice,
			companyDoc,
			currencySymbol,
		};
	},
};
</script>

<style lang="scss" scoped>
.modal-content {
	// max-width: 50vw;
	max-width: 700px;
}

.qty {
	width: 20ch;
}

.item {
	margin-bottom: 1em;
}

.interval-switch-text {
	margin-left: 1em;
}

.help-text {
	color: $rm-grey;
	font-style: italic;
}
</style>
