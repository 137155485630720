<template>
    <Sidebar />
</template>

<script>
import Sidebar from "../../components/Sidebar.vue";

export default {
    name: "Account",
    components: { Sidebar },
};
</script>

<style lang="scss" scoped>
</style>