<template>
	<div class="vertical-center">
		<div v-if="!isLoading" class="centered">
			<h1>Purchase a subscription</h1>
			<p v-if="status == 'free'">
				You're currently on a free trial account which limits Equipment,
				Checklists and Contributors
			</p>
			<p v-else>
				You're subscription has been cancelled, to reactivate your
				account, please purchase a subscription.
			</p>
			<div v-if="showStripeRedirectMessage">
				<p>
					Creating secure Stripe checkout url. This may take a few
					seconds...
				</p>
			</div>
			<br />
		</div>

		<page-loading v-if="isLoading" />
		<form v-else class="rm-form tile" @submit.prevent="checkout">
			<div class="select-interval">
				<div class="form-input">
					<label class="switch">
						<input type="checkbox" v-model="selectedInterval" />
						<span class="slider round"></span>
					</label>
				</div>
				<p>{{ intervalText }}</p>
			</div>

			<div class="split">
				<div class="product-col">
					<div class="content">
						<h2>Team Members</h2>
						<h3>Free!</h3>
						<p>Complete checklists and actions.</p>
					</div>
				</div>
				<div class="product-col">
					<div class="content">
						<h2>Contributors</h2>
						<h3>
							{{ currencySymbol
							}}{{
								interval == "month"
									? monthlyContributorUnitPrice + " / month"
									: Math.round(
											yearlyContributorUnitPrice / 12
									  ) + " / month"
							}}
						</h3>
						<p>Manage equipment, checklist templates and more.</p>
					</div>

					<div class="form-input number">
						<input
							type="number"
							:min="minimumContributors"
							v-model="selectedContributors"
						/>
					</div>
				</div>

				<div class="product-col">
					<div class="content">
						<h2>Equipment Packs</h2>
						<h3>
							{{ currencySymbol
							}}{{
								interval == "month"
									? monthlyEquipmentUnitPrice + " / month"
									: Math.round(
											yearlyEquipmentUnitPrice / 12
									  ) + " / month"
							}}
						</h3>
						<p>
							500 active equipment records (archived items are
							free).
						</p>
					</div>

					<div class="form-input number">
						<input
							type="number"
							:min="minimumEquipment"
							v-model="selectedEquipment"
						/>
					</div>
				</div>
			</div>
			<div class="form-submit">
				<button class="btn btn-primary" type="submit">Purchase</button>
			</div>
		</form>
	</div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import PageLoading from "../../../components/PageLoading.vue";
import { getCheckoutURL } from "../../../composables/createSubscription";
import EnvUtils from "../../../misc/env";
import { projectFirestore } from "../../../firebase/config";
import { event } from "vue-gtag";
import fetchProducts from "../../../composables/getAllProducts";
import locationCollection from "../../../composables/locationCollection";

export default {
	components: { PageLoading },
	name: "Purchase",
	props: ["companyId", "status"],
	setup(props) {
		const companyId = props.companyId;
		const status = props.status;
		const { getFirstLocation } = locationCollection();

		const selectedContributors = ref(1);
		const selectedEquipment = ref(1);

		const selectedInterval = ref(true); // false for 'month', true for 'year'
		const interval = computed(() =>
			selectedInterval.value ? "year" : "month"
		);

		const euroCountries = [
			"Austria",
			"Belgium",
			"Cyprus",
			"Estonia",
			"Finland",
			"France",
			"Germany",
			"Greece",
			"Ireland",
			"Italy",
			"Latvia",
			"Lithuania",
			"Luxembourg",
			"Malta",
			"Netherlands",
			"Portugal",
			"Slovakia",
			"Slovenia",
			"Spain",
		];

		const currencySymbol = ref("£");

		const intervalText = computed(() => {
			return selectedInterval.value
				? `Billed annually (3 months free)`
				: "Billed monthly";
		});

		const monthlyContributorUnitPrice = ref("");
		const yearlyContributorUnitPrice = ref("");
		const monthlyEquipmentUnitPrice = ref("");
		const yearlyEquipmentUnitPrice = ref("");

		const companyRef = projectFirestore
			.collection("companies")
			.doc(companyId);

		const error = ref(null);

		const minimumContributors = ref(1);
		const minimumEquipment = ref(1);

		const billingLocation = ref({});

		const showStripeRedirectMessage = ref(false);

		// Used to check the number of contributors and equipment
		const fetchMinimums = async () => {
			try {
				const contributors = await companyRef
					.collection("employees")
					.where("role", "!=", "Employee")
					.get();

				const equipment = await companyRef
					.collection("equipment")
					.get();

				minimumContributors.value = contributors.docs.length;
				selectedContributors.value = contributors.docs.length;
				// This rounds up to the closest 500
				minimumEquipment.value = -1 * ~(equipment.docs.length / 500);
				selectedEquipment.value = -1 * ~(equipment.docs.length / 500);
			} catch (e) {
				console.error("Error fetching minimums", e);
				error.value = "Something went wrong, please try again later.";
			}
		};

		const determineCurrency = () => {
			const country = billingLocation.value.country;
			if (country === "United Kingdom") {
				return "gbp";
			} else if (euroCountries.includes(country)) {
				return "eur";
			} else {
				return "usd";
			}
		};

		const handleRefs = (products) => {
			let contributorProductData = {};
			let equipmentProductData = {};
			const currency = determineCurrency(billingLocation.value);

			for (const product of products) {
				if (product.name === "Contributor") {
					contributorProductData = product;
				} else {
					equipmentProductData = product;
				}
			}

			for (const price of contributorProductData.prices) {
				if (price.interval === "month" && price.currency === currency) {
					monthlyContributorUnitPrice.value = price.unit_amount / 100;
				}
				if (price.interval === "year" && price.currency === currency) {
					yearlyContributorUnitPrice.value = price.unit_amount / 100;
				}
			}

			for (const price of equipmentProductData.prices) {
				if (price.interval === "month" && price.currency === currency) {
					monthlyEquipmentUnitPrice.value = price.unit_amount / 100;
				}

				if (price.interval === "year" && price.currency === currency) {
					yearlyEquipmentUnitPrice.value = price.unit_amount / 100;
				}
			}

			if (currency === "usd") {
				currencySymbol.value = "$";
			} else if (currency === "eur") {
				currencySymbol.value = "€";
			}
		};

		const isLoading = ref(false);

		onMounted(async () => {
			isLoading.value = true;
			let products = [];

			try {
				billingLocation.value = await getFirstLocation();
				await fetchMinimums();
				products = await fetchProducts();
			} catch (e) {
				console.error("fetch onMounted error: ", e);
				error.value = "Something went wrong, please try again later.";
			}

			handleRefs(products);
			isLoading.value = false;
		});

		const checkout = async () => {
			isLoading.value = true;
			event("Purchase redirect", { method: "Google" });

			const successURL = EnvUtils.PORTAL + "/success";
			const failureURL = EnvUtils.PORTAL + "/subscription-error";

			const currency = determineCurrency(billingLocation.value);

			showStripeRedirectMessage.value = true;

			// This will redirect the user to stripe checkout
			await getCheckoutURL(
				successURL,
				failureURL,
				selectedContributors.value,
				selectedEquipment.value,
				interval.value,
				Rewardful.referral,
				currency
			);
		};

		return {
			selectedContributors,
			selectedEquipment,
			selectedInterval,
			interval,
			intervalText,
			monthlyContributorUnitPrice,
			yearlyContributorUnitPrice,
			monthlyEquipmentUnitPrice,
			yearlyEquipmentUnitPrice,
			currencySymbol,
			checkout,
			minimumContributors,
			minimumEquipment,
			isLoading,
			status,
			showStripeRedirectMessage,
		};
	},
};
</script>

<style lang="scss" scoped>
.centered {
	text-align: center;
}
.product-col {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 1em;
	margin: 0 1em;
	max-width: 300px;
	.content {
		display: flex;
		flex-direction: column;
		gap: 1em;
		justify-content: center;
		align-items: center;
		text-align: center;
		margin-bottom: 40px;
	}
}

.select-interval {
	display: flex;
	justify-content: center;
	align-items: center;
	width: fit-content;
	margin: 0 auto;
	margin-bottom: 1em;
	gap: 2em;
	p {
		flex: 1;
		min-width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.switch {
	margin-top: 14px;
}

.number {
	width: 100%;
	text-align: center !important;
	input {
		max-width: 100px;
	}
}
</style>
