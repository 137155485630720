<template>
    <div class="right-content">
        <div class="right-nav" v-if="!role">
            <div class="page-title">
                <div class="lines shine"></div>
            </div>
            <div class="page-links">
                <div class="lines shine"></div>
                <div class="lines shine"></div>
            </div>
        </div>
        <AccountNav v-if="role && role == 'Admin'" />
        <div class="page-content">
            <div class="page-detail">
                <div v-if="role && role != 'Admin'" class="flex-centered">
                    <p>Access denied! You must have an admin account to access settings</p>
                </div>
                <AccountSettings v-else />

                <div></div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import account from "../../composables/account";
import AccountNav from "../../components/account/AccountNav.vue";
import AccountSettings from "../../components/account/AccountSettings.vue";
import fetchSubscription from "../../composables/fetchSubscription";
import { onMounted } from "vue";
import { useMeta } from "vue-meta";

export default {
    name: "AccountDash",
    components: {
        AccountNav,
        AccountSettings,
    },
    setup() {
        useMeta({ title: "Account" });
        const { role } = account();
        const subscription = ref(null);

        onMounted(async () => {
            subscription.value = await fetchSubscription();
        });

        return {
            role,
            subscription,
        };
    },
};
</script>

<style lang="scss">
.lines {
    height: 1.2em;
    width: 100px;
    border-radius: 6px;
}
.right-content .page-content{
    position: inherit;
}
</style>
