<template>
	<div class="right-content">
		<AccountNav />
		<div class="vertical-center move-up" v-if="!companyDoc || !user">
			<PageLoading />
		</div>

		<div v-else>
			<div class="move-up" v-if="showPurchase">
				<Purchase
					:companyId="companyDoc.id"
					:status="companyDoc.subscription.status"
					v-if="user.uid === companyDoc.ownerUid"
				/>
				<div v-else class="vertical-center">
					<h1>Purchase a subscription</h1>
					<p>Please contact the account owner to purchace a</p>
				</div>
			</div>

			<!-- Active, incomplete and unpaid -->
			<div class="page-content" v-else>
				<SubscriptionDetails />
			</div>
		</div>
	</div>
</template>

<script>
import { computed } from "vue";
import PageLoading from "../../../components/PageLoading.vue";
import AccountNav from "../../../components/account/AccountNav.vue";
import SubscriptionDetails from "../../../components/account/SubscriptionDetails.vue";
import { useSessionStore } from "@/stores/sessionStore";
import { storeToRefs } from "pinia";
import Purchase from "../../../views/account/subscription/Purchase.vue";

export default {
	name: "Subscription",
	components: {
		PageLoading,
		AccountNav,
		SubscriptionDetails,
		Purchase,
	},
	setup() {
		const sessionStore = useSessionStore();
		const { user, companyDoc } = storeToRefs(sessionStore);

		const showPurchase = computed(() => {
			if (companyDoc.value) {
				if (
					companyDoc.value.subscription.status === "free" ||
					companyDoc.value.subscription.status === "incomplete" ||
					companyDoc.value.subscription.status ===
						"incomplete_expired"
				) {
					return true;
				}
				const today = new Date();
				const current_period_end = new Date(
					companyDoc.value.subscription.current_period_end.seconds *
						1000
				);
				if (
					companyDoc.value.subscription.status === "canceled" &&
					today > current_period_end
				) {
					return true;
				}
			}
		});

		return {
			user,
			companyDoc,
			showPurchase,
		};
	},
};
</script>

<style lang="scss" scoped>
.move-up {
	margin-top: -100px;
}
.page-content {
	top: 210px !important;
	left: 0 !important;

	@include respond(small) {
		top: 185px !important;
	}
	@include respond(medium) {
		top: 115px !important;
		left: $nav-width !important;
	}
}
</style>
