<template>
    <div class="right-nav">
        <div class="page-title">
            <h2>Settings</h2>
        </div>
        <div class="page-links">
            <ul>
                <li>
                    <router-link class="nav-link" :to="{ name: 'AccountDash' }">Account Settings</router-link>
                </li>
                <li>
                    <router-link class="nav-link" :to="{ name: 'Subscription' }">Subscription</router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "AccountNav",
};
</script>

<style lang="scss" scoped>
// .page-links {
//   ul {
//     display: flex;
//     gap: 2em;
//   }
// }

.page-links{
    ul{
      gap: 1em !important;
      @include respond(large){
        gap: 4em !important;
      }
        li{
            font-size: 13px !important;
    
            @include respond(small){
                font-size: 14px !important;
                }
            @include respond(large){
                font-size: 15px !important;
                }
        }
    }
}
</style>
