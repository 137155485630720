<template>
	<div v-if="!companyData">
		<sub-nav-loading />
		<div class="page-container">
			<div class="table-row-shimmer shine"></div>
		</div>
	</div>

	<div class="page-content" v-else>
		<div class="tile" v-if="companyData">
			<!-- Current Sub details -->
			<div class="split">
				<!-- Left Content -->
				<div class="col">
					<div class="row">
						<div class="col padding-right">
							<div class="flex">
								<h3>Current subscription</h3>
								<div
									class="status-block"
									v-bind:class="{
										active:
											companyData.subscription.status ==
											'active',
										warning:
											companyData.subscription.status ==
											'past_due',
										danger:
											companyData.subscription.status ==
												'unpaid' ||
											companyData.subscription.status ==
												'canceled',
									}"
								>
									{{ companyData.subscription.status }}
								</div>
							</div>

							<div
								v-if="
									companyData.subscription.status == 'active'
								"
							>
								<p>
									Your subscription is active and you have
									full access to all features.
								</p>
							</div>
							<div
								v-else-if="
									companyData.subscription.status ==
									'past_due'
								"
							>
								<p>
									Your subscription payment is past due.
									Please update your payment information to
									avoid your account being deactivated.
								</p>
							</div>
							<div
								v-else-if="
									companyData.subscription.status == 'unpaid'
								"
							>
								<p>
									We've been unable to charge your payment
									method. Please update your payment
									information to reactivate your account or
									contact support.
								</p>
							</div>
							<div
								v-else-if="
									companyData.subscription.status ==
									'canceled'
								"
							>
								<p>
									Your subscription has been canceled and will
									be deactivated at the end of the current
									billing cycle.
								</p>
							</div>
							<br />
							<button
								class="btn btn-outline fit-content"
								@click="openCustomerPortal"
								v-if="!isOpeningCustomerPortal && isOwner"
							>
								Billing & Invoices
							</button>
							<div
								style="width: 15ch"
								v-if="isOpeningCustomerPortal"
							>
								<page-loading />
							</div>
						</div>
					</div>
				</div>
				<!-- Right Content -->
				<div class="col">
					<div class="row">
						<div class="col">
							<br />
							<p>Contributors</p>
							<p>Active equipment</p>
						</div>
						<div class="col center">
							<p>
								<b>In use</b>
							</p>
							<p>{{ contributorsInUse }}</p>
							<p>{{ activeEquipment }}</p>
						</div>
						<div
							class="col center"
							v-bind:class="{
								muted:
									companyData.subscription.status ==
										'past_due' ||
									companyData.subscription.status ==
										'unpaid' ||
									companyData.subscription.status ==
										'canceled',
							}"
						>
							<p>
								<b>Available</b>
							</p>
							<p>
								{{ companyData.subscription.max_contributors }}
							</p>
							<p>
								{{
									companyData.subscription.max_equipments *
									500
								}}
							</p>
						</div>
					</div>
					<div class="row">
						<div class="col"></div>
						<div class="col"></div>
						<div class="col">
							<router-link
								class="btn btn-success link-btn"
								v-if="
									isOwner &&
									companyData.subscription.status ==
										'active' &&
									!companyData.subscription
										.cancel_at_period_end
								"
								:to="{ name: 'Upgrade' }"
								>Upgrade Subscription</router-link
							>
							<!-- <button
								v-if="
									isOwner &&
									companyData.subscription.status ==
										'active' &&
									!companyData.subscription
										.cancel_at_period_end
								"
								class="btn btn-success"
							>
								Edit Subscription
							</button> -->
						</div>
					</div>
				</div>
			</div>
			<div class="spacer-40"></div>
			<div class="row-line"></div>
			<div class="spacer-40"></div>
			<!-- Manage next sub -->
			<div class="split">
				<!-- Left column -->
				<div class="col">
					<div class="row">
						<div class="col padding-right">
							<!-- The user has canceled the subscription -->
							<div
								v-if="
									companyData.subscription
										.cancel_at_period_end
								"
							>
								<h3>
									This subscription will be canceled on
									{{ nextBillingDate }}
								</h3>
								<br />
								<p>
									We're sorry to see you go! To renew your
									subscription click 'Billing & Invoices' and
									then 'Renew'.
								</p>
							</div>

							<!-- The user is active and paid -->
							<div
								v-else-if="
									companyData.subscription.status == 'active'
								"
							>
								<h3>
									Next payment is on {{ nextBillingDate }}
								</h3>
							</div>

							<!-- We've failed to charge the customers payment method or an invoice is unpaid -->
							<div
								v-else-if="
									companyData.subscription.status ==
									'past_due'
								"
							>
								<h3>
									This subscription will be deactivated soon
								</h3>
							</div>

							<div
								v-else-if="
									companyData.subscription.status == 'unpaid'
								"
							>
								<h3>This subscription has been deactivated</h3>
							</div>
							<router-link
								class="muted no-padding"
								v-if="
									isSubOwner &&
									!companyData.subscription
										.cancel_at_period_end
								"
								:to="{
									name: 'CancelSubscription',
									params: {
										subscriptionId:
											companyData.subscription.id,
									},
								}"
							>
								<b>Cancel Subscription</b>
							</router-link>
						</div>
					</div>
				</div>
				<!-- right -->
				<div v-if="!companyData.cancel_at_period_end" class="col">
					<div class="row">
						<div class="col">
							<br />
							<p>Contributors</p>
							<p>Equipment</p>
							<p class="muted">
								<i>Subscriptions downgrade automatically</i>
							</p>
						</div>
						<div class="col">
							<br />
							<br />
							<br />
							<br />
						</div>
						<div class="col center">
							<br />
							<p>{{ contributorChangeString }}</p>
							<p>{{ equipmentChangeString }}</p>
							<br />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div
		class="alert-container"
		v-if="companyData.subscription.status == 'unpaid'"
	>
		<div class="alert-block">
			<h3>Payment overdue or incomplete</h3>
			<p>
				<b
					>This subscription has been deactivated and account
					restricted because there's been an issue charging your
					payment method.</b
				>
				Click "Billing & Invoices" to edit your billing and payment
				information. If updating your payment method does not resolve
				the issue, please contact support.
			</p>
		</div>
	</div>
	<router-view v-slot="{ Component }">
		<transition name="fade" mode="in-out">
			<component :is="Component" />
		</transition>
	</router-view>
</template>

<script>
import { projectFirestore, projectFunctions } from "../../firebase/config";
import { computed, onMounted, ref } from "@vue/runtime-core";
import getCollection from "../../composables/getCollection";
import moment from "moment";
import SubNavLoading from "../SubNavLoading.vue";
import PageLoading from "../PageLoading.vue";
import { useSessionStore } from "@/stores/sessionStore";
import { storeToRefs } from "pinia";
import router from "@/router/index";

export default {
	components: { SubNavLoading, PageLoading },
	name: "SubscriptionDetails",
	setup() {
		const sessionStore = useSessionStore();
		const {
			user,
			companyDoc: companyData,
			companyId,
			employeeDoc: userDoc,
		} = storeToRefs(sessionStore);
		const isOpeningCustomerPortal = ref(false);

		const equipmentRef = projectFirestore
			.collection("companies")
			.doc(companyId.value)
			.collection("equipment")
			.where("archived", "==", false);

		const { documents: equipment } = getCollection(equipmentRef);

		onMounted(async () => {});

		// It will fetch the URL for customer portal on stripe and redirect user to it.
		const openCustomerPortal = async () => {
			isOpeningCustomerPortal.value = true;

			const functionRef = projectFunctions.httpsCallable(
				"createStripePortalLink"
			);

			const { data } = await functionRef({
				ownerId: companyData.value.ownerUid,
				returnUrl: window.location.origin + "/account/subscription",
			});

			isOpeningCustomerPortal.value = false;

			window.open(data.url);
		};

		const interval = computed(() => {
			if (companyData.value) {
				if (companyData.value.subscription.interval === "month") {
					return "Monthly";
				} else {
					return "yearly";
				}
			}
		});

		const contributorChangeString = computed(() => {
			if (companyData.value) {
				let maxContributors =
					companyData.value.subscription.max_contributors;
				let usedContributors =
					companyData.value.subscription.used_contributors ?? 1;

				if (maxContributors === usedContributors) {
					return maxContributors + " (No Change)";
				} else {
					return (
						"From " + maxContributors + " to " + usedContributors
					);
				}
			}
		});

		const contributorsInUse = computed(() => {
			if (companyData.value) {
				return companyData.value.subscription.used_contributors ?? 1;
			}
		});

		const activeEquipment = computed(() => {
			if (equipment.value) {
				return equipment.value.length;
			}
		});

		const equipmentChangeString = computed(() => {
			if (equipment.value) {
				let usedEquipments = -1 * ~(equipment.value.length / 500);
				let maxEquipments =
					companyData.value.subscription.max_equipments;

				if (maxEquipments === usedEquipments) {
					return maxEquipments * 500 + " (No Change)";
				} else {
					return (
						"From " +
						maxEquipments * 500 +
						" to " +
						usedEquipments * 500
					);
				}
			}
		});

		const isOwner = computed(() => {
			if (userDoc.value && userDoc.value.owner) {
				return true;
			}
		});

		const isSubOwner = computed(() => {
			if (user.value.uid == companyData.value.ownerUid) {
				return true;
			}
		});

		const nextBillingDate = computed(() => {
			let formattedDate;
			if (companyData.value) {
				let timestamp =
					companyData.value.subscription.current_period_end.seconds *
					1000;
				formattedDate = moment(timestamp).format("dddd Do MMMM, yyyy");
			}

			return formattedDate;
		});

		return {
			companyData,
			isOwner,
			interval,
			contributorChangeString,
			equipmentChangeString,
			contributorsInUse,
			activeEquipment,
			nextBillingDate,
			openCustomerPortal,
			isOpeningCustomerPortal,
			isSubOwner,
		};
	},
};
</script>

<style lang="scss" scoped>
.tile {
	margin: 1em;
	padding: 2em;
}

.padding-right {
	padding-right: 5em;
}

.no-padding {
	padding: 0;
}

.btn {
	font-weight: normal;
}

.fit-content {
	width: fit-content;
}

.table-row-shimmer {
	height: 300px;
	border-radius: 1em;
}

.row {
	display: flex;
	justify-content: space-between;
	gap: 1em;
}

.col {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 1em;
}

.center {
	text-align: center;
}

.status-block {
	width: fit-content;
	padding: 0 0.5em;
	border-radius: $border-radius;
	color: white;
	margin-left: 1em;
	font-weight: normal;
}

.active {
	background-color: $rm-success;
}

.warning {
	background-color: $rm-warning;
}

.danger {
	background-color: $rm-danger;
}

.white {
	color: white;
}

.alert-container {
	position: absolute;
	bottom: 0;
	width: 100%;
	.alert-block {
		width: 50vw;
		margin: 0 auto;
		margin-bottom: 1em;
		background-color: $rm-danger;
		color: white;
		padding: 1em;
		border-radius: 8px;
		h3 {
			text-decoration: underline;
		}
	}
}

.link-btn {
	font-size: 0.9em;
	font-weight: normal;
	padding: 0.4em 1em;
}
</style>
