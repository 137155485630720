import { ref } from 'vue'
import { projectAuth, projectStorage } from '../firebase/config'

const useStorage = () => {
    const error = ref(null)
    const downloadUrl = ref(null)
    const filePath = ref(null)

    const uploadProfileImg = async (file) => {
        filePath.value = `profile-images/${projectAuth.currentUser.uid}/${file.name}`
        const storageRef = projectStorage.ref(filePath.value)

        try {
            const res = await storageRef.put(file)
            downloadUrl.value = await res.ref.getDownloadURL()
        } catch(err) {
            console.log(err.message)
            error.value = 'Failed to upload file'
        }
    }

    const uploadCompanyImages = async (file, companyId) => {
        filePath.value = `company-images/${companyId}/${file.name}`
        const storageRef = projectStorage.ref(filePath.value)

        try {
            const res = await storageRef.put(file)
            downloadUrl.value = await res.ref.getDownloadURL()
        } catch(err) {
            console.log(err.message)
            error.value = 'Failed to upload file'
        }
    }

    const uploadFile = async (filePath, file) => {
        // filePath.value = `documents/${companyId}/${documentId}/${file.name}`
        const storageRef = projectStorage.ref(filePath)

        try {
            const res = await storageRef.put(file)
            downloadUrl.value = await res.ref.getDownloadURL()
        } catch(err) {
            console.log(err.message)
            error.value = 'Failed to upload file.'
        }
    }

    const deleteFile = async (filePath) => {
        const storageRef = projectStorage.ref(filePath)

        try {
            await storageRef.delete()
        } catch(err) {
            console.log(err.massage)
            error.value = "Failed to delete file."
        }
    }
   
    return { error, downloadUrl, filePath, uploadProfileImg, uploadCompanyImages, uploadFile, deleteFile }
}

export default useStorage